import React from 'react'

import { StaticQuery, graphql } from "gatsby"
//import get from 'lodash/get'


import { Link } from 'gatsby'
import base from './base.scss'
//import supportStyles from './sass/style.scss'
import Container from './container'
import Navigation from './navigation'
import Hero from '../components/hero'

import GetContactSection from '../components/get-contact-section'

//import Header from "./search"

import AlertSection from '../components/alert-section'
import CookieBanner from '../components/cookie-banner'
import packageJson from '../../package.json';
import Img from 'gatsby-image'


import Footer from './footer'

if (typeof window !== 'undefined') {
    window.jQuery = window.$ = require('jquery');
    require('bootstrap');
}

class Template extends React.Component {
   componentDidMount() {
   var easingFn = function (t, b, c, d) {
      var ts = (t /= d) * t;
      var tc = ts * t;
      return b + c * (tc + -3 * ts + 3 * t);
    }

     $('.panel-collapse').on('shown.bs.collapse', function (e) {
      var $panel = $(this).closest('.panel');
      var $parentClass = $(this).parent().parent().parent().parent().prop('className');
      var id = $(this).attr('id');
      if( $parentClass == "panel-collapse collapse in") {
        return;
      }
      $('html,body').animate({
          scrollTop: $panel.offset().top - 48
      }, 720, easingFn); 
  }); 

     $('.modal').on('show.bs.modal', function (e) {
      if($(this).has('iframe').length) {
        //console.log($(this).find('iframe').attr("src")[0]);
        var newURL = $(this).find('iframe').attr("src").split('?')[0] + ($(this).find('iframe').attr("src").split('?')[0].indexOf('?') < 0 ? '?' : '&') + 'autoplay=1';
        

        $(this).find('iframe').attr('src', newURL);
      }
      
     }); 
     $('.modal').on('hide.bs.modal', function (e) {
      if($(this).has('iframe').length) {
        var videoURL = $(this).find('iframe').attr("src");
        $(this).find('iframe').attr("src","");
        //very hacky! 
        $(this).find('iframe').attr("src",videoURL.slice(0, -11));
      }
});

       checkCookie_eu();

       function checkCookie_eu() {
         var consent = getCookie_eu("cookies_consent");

     if (consent == null || consent == "" || consent == undefined) {
             // show notification bar
             $('#cookie-disclaimer-container').show();
             $('body').addClass("cookie");
             $('#mainNav').addClass("cookie");
           }

         }

         function setCookie_eu(c_name,value,exdays) {
           var exdate = new Date();
           exdate.setDate(exdate.getDate() + exdays);
           var c_value = escape(value) + ((exdays==null) ? "" : "; expires="+exdate.toUTCString());
           document.cookie = c_name + "=" + c_value+"; path=/";

           $('#cookie-disclaimer-container').hide('slow');
           $('body').removeClass("cookie");
           $('#mainNav').removeClass("cookie");
         }


         function getCookie_eu(c_name) {
           var i,x,y,ARRcookies=document.cookie.split(";");
           for (i=0;i<ARRcookies.length;i++)
           {
             x=ARRcookies[i].substr(0,ARRcookies[i].indexOf("="));
             y=ARRcookies[i].substr(ARRcookies[i].indexOf("=")+1);
             x=x.replace(/^\s+|\s+$/g,"");
             if (x==c_name)
             {
               return unescape(y);
             }
           }
         }

         $("#cookie_accept a.pull-right").click(function(){
           setCookie_eu("cookies_consent", 1, 30);
           $(window).trigger('resize'); //added to fix parallax
         });

  }
  render() {
    const { location, children, title, headerImage } = this.props


    var largeHeading = ''
    var headerImageData = ''

    if(this.props.largeHeading){
      largeHeading = this.props.largeHeading
    }

    if(this.props.headerImage){
      headerImageData = this.props.headerImage
    }

   // largeHeading = 'The page you\'re looking for can\'t be found'



    let header

    let rootPath = `/`
    if (typeof __PREFIX_PATHS__ !== `undefined` && __PREFIX_PATHS__) {
      rootPath = __PATH_PREFIX__ + `/`
    }

    return (
      <Container>
        <Navigation />
        <CookieBanner />
        <Hero headerImage={headerImageData} largeHeading={largeHeading}/>
        <AlertSection />
        {children}
        <GetContactSection title={title}/>
        <Footer currentYear={new Date().getFullYear()} />
        <span style={{display:'none'}}>{`YouView Support: v${packageJson.version}`}</span>
      </Container>
    )
  }
}

export default Template
